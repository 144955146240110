* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

.CodeMirror {
  font-family: 'Courier New', Courier, monospace !important;
  font-size: 16px;
}

#code {
  position: absolute;
  overflow: hidden;
}
